'use client';

import { useEffect } from 'react';
import Image from 'next/image';
import { SfButton } from '@/components/ui/sf';
import { reportToSentry } from '@/utils/reportToSentry';
function ErrorPage({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    reportToSentry(new Error('Error Page Displayed'), {
      error: JSON.stringify(error),
      digest: error.digest
    });
  }, []);
  return <div className="flex flex-col items-center min-h-w px-5 mt-[35px]" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
      <Image src="/error_page_graphic.svg" alt="Error Image" className="mt-[35px]" width={284} height={143} data-sentry-element="Image" data-sentry-source-file="error.tsx" />
      <h1 className="my-4 font-medium text-xl text-center">
        We’ve run into a server error. Please go back and try again.
      </h1>
      <SfButton onClick={reset} data-sentry-element="SfButton" data-sentry-source-file="error.tsx">Go Back</SfButton>
      <p className="text-error mt-4 text-center">If the problem persists, please logout and try logging back in.</p>
    </div>;
}
export default ErrorPage;